import * as React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionHeading from "../components/SectionHeading"
import SectionParagraph from "../components/SectionParagraph"
import HomeBannerSlider from "../components/HomeBannerSlider"
import SectionButton from "../components/SectionButton"
import Background from "../images/section-background.png"
import LogoBlock from "../components/LogoBlock"
import Favicon from "../images/w-health-favicon.png"
const HomeBannerSection = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    height:768px;
    position:relative;
    @media screen and (max-width: 991px) {
        flex-direction:column;
        height:auto;
    }
    @media screen and (max-width: 767px) {
      
    }
    @media screen and (max-width: 479px) {      
        
    }
    `;
const HomeBannerContentWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    width:50%;
    padding:0 5% 0 2.5%;
    height:100%;
    @media screen and (max-width: 991px) {
        width:100%;
        padding:0 2.5% 0 2.5%;
    }
`;
const HomeSliderContainer = styled.div`
    width:50%;
    height:100%;  
    z-index:3;
    @media screen and (max-width: 991px) {
        width:100%;
    }
`;
const BackSection = styled.div`
    position:absolute;
    top:100px;
    left:auto;
    right:0%;
    height:130%;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 90%;
    background-image:url(${Background});
    opacity:0.8;
    background-size:cover;
    background-position:0% 50%;
    @media screen and (max-width: 991px) {
        width: 100%;
        height:100%;
        top:200px;
        background-size:contain;
        background-position:top;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 479px) {
        width: 100%;
        height:150vh;
        top:100px;
        background-size:cover;
        background-position:0% 50%;
        background-attachment: fixed;
    }
`;
const TopWrap = styled.div`
    display:flex;
    height:100%;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    @media screen and (max-width: 991px) {
        margin:0px 0 100px 0;
        min-height:40vh;
        justify-content:space-around;
        width:100%;
    }
    @media screen and (max-width: 479px) {
        margin:0px 0 40px 0;
    }
`;
const VisionSection = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    align-items:flex-start;
    padding:60px 2.5% 100px 2.5%;
    @media screen and (max-width: 991px) {
        padding:80px 2.5% 100px 2.5%;
    }
    @media screen and (max-width: 991px) {
        padding:40px 2.5% 60px 2.5%;
    }
`;
const VisionContainer = styled.div`
    margin-top:10px;
    display:flex;
    width:100%;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
`;
const VisionRow = styled(VisionContainer)`
    margin-bottom:10px;
    width:50%;
    @media screen and (max-width: 991px) {
        width:70%;
    }
    @media screen and (max-width: 479px) {
        width:100%;
    }
`;
const RowHeading = styled.h2`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin-bottom:10px;
`;
const ButtonRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    margin-top:20px;
`;
const Breadcrumb = styled.h1`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:20px 0 0px 0;
    @media screen and (max-width: 991px) {
        margin-bottom:10vh;
    }
    @media screen and (max-width: 479px) {
        margin:40px 0 5vh 0;
    }
`;
const AboutPage = () => (
    <Layout>
        <Seo title="About Us" url="https://whealthventures.com/about-us" image={Favicon} />
        <HomeBannerSection>
            <HomeBannerContentWrap>
                <LogoBlock></LogoBlock>
                <Breadcrumb>About Us</Breadcrumb>
                <TopWrap>
                    <SectionHeading>We support inspiring change-makers in healthcare.</SectionHeading>
                    <SectionParagraph>W Health Ventures is a Boston-based venture capital firm focused on helping entrepreneurs build the future of healthcare delivery.</SectionParagraph>
                    <SectionParagraph>Founded by serial technology entrepreneur, investor, and philanthropist Sunil Wadhwani, W Health invests in early-stage companies that address critical unmet healthcare needs leveraging technology and AI in the US and India.</SectionParagraph>
                    <SectionParagraph>The W Health team and advisory network comprise seasoned investors, physician leaders, technology experts, and healthcare operators in the US and India.</SectionParagraph>
                </TopWrap>
            </HomeBannerContentWrap>
            <HomeSliderContainer>
                <HomeBannerSlider home={false} image={"AU_Banner1.jpg"} objectPosition={"60% 50%"} />
            </HomeSliderContainer>
            <BackSection></BackSection>
        </HomeBannerSection>
        <VisionSection>
            <SectionHeading>Vision And Mission</SectionHeading>
            <VisionContainer>
                <VisionRow>
                    <RowHeading>Our Vision</RowHeading>
                    <SectionParagraph top={"0px"} bottom={"0px"}>The healthcare system is broken across the world. This has created large unmet patient needs. W Health Ventures aims to solve critical challenges faced by people due to lack of access, lack of awareness and poor-quality healthcare.</SectionParagraph>
                </VisionRow>
                <VisionRow>
                    <RowHeading>Our Mission</RowHeading>
                    <SectionParagraph top={"0px"}>To touch 100 million lives through the enterprises we help fund and grow</SectionParagraph>
                </VisionRow>
                <ButtonRow>
                    <SectionButton to={"/career"}>Join Us</SectionButton>
                    <SectionButton to={"/team"} left={"20px"}>Our Team</SectionButton>
                </ButtonRow>
            </VisionContainer>
        </VisionSection>
    </Layout>
)

export default AboutPage
